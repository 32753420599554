import React, { useEffect, useState } from 'react'
import {
  Modal,
  Input,
  Radio,
  Select,
  DatePicker,
  Space,
  TimePicker,
  Form,
  Spin,
  Alert
} from 'antd'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { handleError } from 'utils/handleApiResponse'

const ModalRegistroIncumplimientos = ({
  setRefresh,
  modalRegistro,
  setModalRegistro,
  setModalInfo,
  setShowModal,
  setErrors,
  setIsBlocked,
  isBlocked,
  accountName,
  rut,
}) => {
  const [form] = Form.useForm()
  const [sector, setSector] = useState(false)
  const [isLodingSubmit, setisLoadingSubmit] = useState(false)

  const { state } = useLocation()
  const { identificador, type_identificador } = state
  const format = 'HH:mm'
  const [showAlert, setShowAlert] = useState(false)
  const rules = [
    {
      required: true,
      message: 'Campo requerido'
    }
  ]

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const getStatusLabel = (isBlocked) => {
    return isBlocked === 0 ? "Bloqueado" : "Desbloqueado";
  };


  const cerrarIr = () => {
    form.resetFields()
    setModalRegistro(false)
    setShowAlert(false)
  }


  const layout = () => {
    if (isBlocked === 0) {
      return (
        <Form
          form={form}
          layout='vertical'
          style={{
            marginTop: '10px'
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Responsable'
            name='responsible'
            rules={rules}
            initialValue={accountName}
          >
            <Input disabled/>
          </Form.Item>

          {/* <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Fecha Registro'
            name='breach_date'
            rules={rules}
            initialValue={getCurrentDateTime()}
          >
            <Input disabled/>
          </Form.Item> */}

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Estado'
            name='isBlocked'
            rules={rules}
            initialValue={getStatusLabel(isBlocked )}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Motivo'
            name='reason'
            rules={[rules , {max: 500}]}
            initialValue={''}
          >
            <Input />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              marginTop: '15px',
              columnGap: '15px'
            }}
          >
            <div className='modal-registros__button-secundary'>
              <SecondaryButton onButtonClick={cerrarIr}>
                Cancelar
              </SecondaryButton>
            </div>
            <div className='modal-registros__button-primary'>
              <PrimaryButton type='primary' isLoading={isLodingSubmit}>
                Bloquear
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )
    } else if (isBlocked === 1) {
      return (
        <Form
          form={form}
          layout='vertical'
          style={{
            marginTop: '10px'
          }}
          onFinish={handleSubmit}
        >
          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Responsable'
            name='responsible'
            rules={rules}
            initialValue={accountName}
          >
            <Input disabled/>
          </Form.Item>

          {/* <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Fecha Registro'
            name='entryAt'
            rules={rules}
            initialValue={getCurrentDateTime()}
          >
            <Input disabled/>
          </Form.Item> */}

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Estado'
            name='isBlocked'
            rules={rules}
            initialValue={getStatusLabel(isBlocked)}
          >
            <Input disabled/>
          </Form.Item>

          <Form.Item
            className='modal-registros-incumplimientos__labelModal'
            label='Motivo'
            name='reason'
            rules={[rules , {max: 500}]}
            initialValue={''}
          >
            <Input />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              justifyItems: 'center',
              marginTop: '15px',
              columnGap: '15px'
            }}
          >
            <div className='modal-registros__button-secundary'>
              <SecondaryButton onButtonClick={cerrarIr}>
                Cancelar
              </SecondaryButton>
            </div>
            <div className='modal-registros__button-primary'>
              <PrimaryButton type='primary' isLoading={isLodingSubmit}>
                Desbloquear
              </PrimaryButton>
            </div>
          </div>
        </Form>
      )
    }
  }
  // const transformBlock = (block) => { block === "Bloqueado" ? 1 : 0 }

  const handleSubmit = async (values) => {
    console.table(values)
    setisLoadingSubmit(true)
    values.isBlocked === "Bloqueado" ? values.isBlocked = 1 : values.isBlocked = 0


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_BREACHES}`,
        {
          ...values,
          rut: rut,
        },
        {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      console.log(response.data.data, "Handle Submit")
    } catch (error) {
      console.error(error)
    }
    setisLoadingSubmit(false)
    setRefresh()
    setModalRegistro(false)
  }

  useEffect(() => {
    if (modalRegistro) {
      form.resetFields()
    }
  }, [modalRegistro])

  return (
    <>
      <div className='modal-registros__container'>
        <Modal
          width={819}
          centered
          open={modalRegistro}
          footer={false}
          maskClosable={false}
          closable={false}
        >
          <p className='modal-registros__textModal'>Registrar Incumplimiento</p>
          {/* {!isLoadInfo && (
            <Radio.Group value={optionIngresos}>
              <Radio
                className='modal-registros__radio'
                value={1}
                disabled={optDisabled.includes(1)}
              >
                Ingreso
              </Radio>
              <Radio
                className='modal-registros__radio'
                value={2}
                disabled={optDisabled.includes(2)}
              >
                Salida
              </Radio>
              <Radio
                className='modal-registros__radio'
                value={3}
                disabled={optDisabled.includes(3)}
              >
                Ingreso y Salida
              </Radio>
            </Radio.Group>
          )} */}

          {layout()}
        </Modal>
      </div>
    </>
  )
}
export default ModalRegistroIncumplimientos
