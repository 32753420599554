import { LeftCircleFilled } from '@ant-design/icons'
import { Divider, Tabs } from 'antd'
import { AppContext } from 'app/AppContext'
import {
  DECLARATIONS,
  EXCEPTIONS,
  INCUMPLIMIENTOS,
  MOVIMIENTOS_USUARIOS
} from 'app_permissions/dashboard'
import axios from 'axios'
import StatCard from 'components/Cards/StatCard'
import UserInfo from 'components/Movimientos/UserInfo'
import TablaRegistroDeclaraciones from 'components/TableContainer/Movimientos/RegistroDeclaraciones'
import TablaRegistroPermisos from 'components/TableContainer/Movimientos/RegistroPermisos'
import TablaRegistroIncumplimientos from 'components/TableContainer/Movimientos/RegistroIncumplimientos'
import TablaRegistroMovimientos from 'components/TableContainer/Movimientos/RegistroMovimientos'
import React, { useContext } from 'react'
import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { userHasPermission } from 'utils/userHasPermission' 
import { useRut } from "utils/FormatRut"

function MovimientosPorUsuario() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { identificador, type_identificador } = state
  const [userInfo, setUserInfo] = React.useState(null)
  const [stats, setStats] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { clean, format } = useRut("");

  const { rolData } = useContext(AppContext)

  const VER_MOVIMIENTOS_USUARIOS = userHasPermission(
    rolData?.role,
    MOVIMIENTOS_USUARIOS.VISUALIZAR
  )
  const VER_DECLARATIONS = userHasPermission(
    rolData?.role,
    DECLARATIONS.VISUALIZAR
  )
  const VER_EXCEPTIONS = userHasPermission(rolData?.role, EXCEPTIONS.VISUALIZAR)
  const VER_INCUMPLIMIENTOS = userHasPermission(
    rolData?.role,
    INCUMPLIMIENTOS.VISUALIZAR
  )

  const getStats = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/find-counter-by-user/${identificador}`
      )

      setStats(response.data)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  const getUserInfo = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_MOVEMENTS}/movements-user/user-info?type_identificador=${type_identificador}&identificador=${identificador}`
      )
      setUserInfo(response.data)
      setLoading(false)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getUserInfo()
    getStats()
  }, [])

  if (
    !VER_MOVIMIENTOS_USUARIOS &&
    !VER_DECLARATIONS &&
    !VER_EXCEPTIONS &&
    !VER_INCUMPLIMIENTOS
  ) {
    return <Navigate to='/first-login' state={{ unauthorizedError: true }} />
  }

  return (
    <div className='registro-movimientos-container'>
      <div className='volver' onClick={() => navigate('/dashboard')}>
        <LeftCircleFilled /> Administrar{' '}
        <span style={{ fontWeight: '600' }}>Colaborador</span>
      </div>
      <Divider />
      <div className='info-cards-container'>
        <StatCard
          type='countApproved'
          count={stats.countApproved ?? 0}
          title='Permitidos'
        />
        <StatCard
          type='countRejected'
          count={stats.countRejected ?? 0}
          title='Denegados'
        />
        <StatCard type='countManual' count={0} title='Manuales' />
        <StatCard type='countException' count={0} title='Excepción' />
        <StatCard type='countDeclaration' count={0} title='Declaración' />
      </div>
      <div className='movimientos-container'>
        <section className='user-info-container'>
          <UserInfo
            profile={userInfo}
            identificador={identificador}
            type_identificador={type_identificador}
            loading={loading}
          />
        </section>
        <section className='movimientos-tables'>
          <Tabs defaultActiveKey='1' type='card' tabBarGutter={16}>
            {VER_MOVIMIENTOS_USUARIOS && (
              <Tabs.TabPane tab='Movimientos' key='1'>
                <TablaRegistroMovimientos setIsLoading={setIsLoading} />
              </Tabs.TabPane>
            )}
            {VER_EXCEPTIONS && (
              <Tabs.TabPane tab='Permisos' key='2'>
                <TablaRegistroPermisos userId={userInfo} stateUser={state} />
              </Tabs.TabPane>
            )}
            {/* {VER_DECLARATIONS && (
              <Tabs.TabPane tab="Declaraciones" key="3">
                <TablaRegistroDeclaraciones />
              </Tabs.TabPane>
            )} */}
            {VER_INCUMPLIMIENTOS && (
              <Tabs.TabPane tab="Incumplimientos" key="4">
                <TablaRegistroIncumplimientos
                  identificador={format(identificador, { dots: false })}
                  type_identificador={type_identificador}
                 />
              </Tabs.TabPane>
            )}
          </Tabs>
        </section>
      </div>
    </div>
  )
}

export default MovimientosPorUsuario
